<template>
  <base-section
    id="affiliates"
    space="50">
    <div 
      data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-center">
    <v-container>
      <base-section-heading title="Telah Diliput Oleh" />

      <v-slide-group>
        <template v-for="(n, index) in media">
          <v-slide-item
            :key="n.images"
            class="align-self-center"
          >
            <base-img
              :src="require(`@/assets/media.jpg`)"
              color="grey"
              contain
              max-height="50"
              max-width="128"
              class="cursor"
            />
            <!-- @click="URL(n.url)" -->
          </v-slide-item>

          <v-responsive
            v-if="index < 4"
            :key="`divider-${index}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',
    data: () => ({
      media: [
        {images: 'media-1.png', url: 'https://www.madaninews.id/7140/takaful-umum-selenggarakan-workshop-move-on-to-sharia.html'},
        {images: 'media-2.png', url: 'https://www.madaninews.id/7140/takaful-umum-selenggarakan-workshop-move-on-to-sharia.html'},
        {images: 'media-3.png', url: 'https://www.topbusiness.id/35439/asuransi-takaful-laznas-yakesma-bersinergi-menggapai-syurga.html'},
        {images: 'media-4.png', url: 'https://mediaasuransinews.co.id/news-in-brief/kerja-sama-aa-international-indonesia-dan-asuransi-takaful-umum.html'},
        {images: 'media-5.png', url: 'https://keuangan.kontan.co.id/news/ini-alasan-kospin-jasa-caplok-saham-asuransi-takaful-umum'},
      ]
    }),
    methods: {
      URL (url) {
        window.open(url)
      }
    }
  }
</script>
